/* Add this at the top of your App.css file */
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@700&display=swap');

/* Copy the contents of your styles.css file here */
body {
	font-family: 'Courier New', monospace;
	line-height: 1.6;
	margin: 0;
	padding: 0;
	background-color: #ffffff;
	color: #000000;
}

.App {
	max-width: 100%;
	margin: 0 auto;
	padding: 20px 5%;
	box-sizing: border-box;
}

.app-header,
main,
.project-header,
.tabs,
.todo-form,
.todo-list {
	width: 100%;
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

header {
	border-bottom: 4px solid #000000;
	margin-bottom: 20px;
	padding-bottom: 10px;
}

h1 {
	font-size: 3em;
	text-transform: uppercase;
	letter-spacing: 4px;
	margin: 0;
}

main {
	margin-top: 20px;
	/* Remove the padding */
}

.todo-form {
	display: flex;
	margin-bottom: 20px;
}

.todo-form input {
	flex-grow: 1;
	padding: 10px;
	font-size: 16px;
	border: 2px solid #000000;
	/* Removed: border-right: none; */
	font-family: 'Courier New', monospace;
}

.todo-form button {
	padding: 0 20px;
	font-size: 14px;
	background-color: #000000;
	color: #ffffff;
	border: 1px solid #000000;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
	/* height: 40px; */
	box-sizing: border-box;
	transition: all 0.3s ease;
}

.todo-form button:hover {
	background-color: #333333;
	color: #ffffff;
	border: 1px solid #000000;
}

.todo-list {
	list-style-type: none;
	padding: 0;
	width: 100%;
}

.todo-item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	margin-bottom: 10px;
	border: 2px solid #000000;
	transition: opacity 0.3s, background-color 0.3s;
	min-height: 40px;
	width: 100%;
	box-sizing: border-box;
}

.todo-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
}

.todo-item input[type='checkbox'] {
	margin-right: 10px;
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border: 2px solid #000000;
	cursor: pointer;
	flex-shrink: 0;
}

.todo-item input[type='checkbox']:checked {
	background-color: #000000;
	position: relative;
}

.todo-item input[type='checkbox']:checked::after {
	content: '✓';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #ffffff;
	font-size: 14px;
}

.todo-item span {
	flex-grow: 1;
	word-break: break-word;
}

.todo-item.completed {
	opacity: 0.6;
	background-color: #f0f0f0;
}

.todo-item.completed span {
	text-decoration: line-through;
}

.task-list {
	margin-top: 20px;
}

.task-list h2 {
	font-size: 2em;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-bottom: 10px;
}

.task-list ul {
	list-style-type: none;
	padding: 0;
}

.task-list li {
	padding: 10px;
	margin-bottom: 10px;
	border: 2px solid #000000;
}

button {
	background-color: #000000;
	color: #ffffff;
	border: none;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
}

button:hover {
	background-color: #ffffff;
	color: #000000;
	border: 2px solid #000000;
}

/* New styles for the checkbox */
.todo-checkbox {
	width: 18px;
	height: 18px;
	border: 2px solid #000000;
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
}

.todo-checkbox.checked::after {
	content: '✓';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 14px;
	color: #000000;
}

.todo-checkbox.uncheckable {
	background-color: #000000;
	cursor: not-allowed;
}

.todo-checkbox.checkable {
	cursor: pointer;
}

#todo-list li span {
	flex-grow: 1;
}

#todo-list li.completed {
	text-decoration: line-through;
	opacity: 0.7;
}

.delete-btn {
	margin-left: auto;
	background-color: transparent;
	color: #000000;
	border: none;
	padding: 2px 5px;
	cursor: pointer;
}

.delete-btn:hover {
	background-color: #f0f0f0;
}

.material-icons {
	font-size: 18px;
}

#todo-list li.priority-1 {
	font-weight: bold;
	background-color: #000000;
	color: #ffffff;
}

#todo-list li.priority-1 .todo-checkbox {
	border-color: #ffffff;
}

#todo-list li.priority-1 .todo-checkbox.checked::after {
	color: #ffffff;
}

#todo-list li.priority-1 .delete-btn {
	color: #ffffff;
}

#todo-list li.priority-1 .delete-btn:hover {
	background-color: #333333;
}

#todo-list li.priority-2,
#todo-list li.priority-3 {
	opacity: 0.8;
}

/* Brutalist-style input focus */
input:focus,
button:focus {
	outline: none;
	/* box-shadow: 0 0 0 4px #000000; */
}

/* Brutalist-style scrollbar */
::-webkit-scrollbar {
	width: 15px;
}

::-webkit-scrollbar-track {
	background: #f0f0f0;
	border: 2px solid #000000;
}

::-webkit-scrollbar-thumb {
	background: #000000;
}

::-webkit-scrollbar-thumb:hover {
	background: #333333;
}

/* Add these styles to your existing App.css file */

.app-header {
	display: flex;
	flex-direction: column;
	padding: 20px 5%;
	background-color: #000000;
	color: #ffffff;
}

.header-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
}

.header-left h1 {
	margin: 0;
	font-size: 3em;
	text-transform: uppercase;
	letter-spacing: 6px;
	font-family: 'Darker Grotesque', sans-serif;
	font-weight: 700;
	/* line-height: 40px; */
	/* height: 40px; */
	display: flex;
	align-items: center;
}

.login-btn {
	background-color: #ffffff;
	color: #000000;
	border: 2px solid #000000;
	padding: 8px 16px;
	font-family: 'Courier New', monospace;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.3s ease;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-btn:hover {
	background-color: #f0f0f0;
}

.avatar {
	background-color: #ffffff;
	color: #000000;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
}

.menu {
	position: absolute;
	right: 100px;
	/* top: 100%; */
	width: 200px;
	background-color: #ffffff;
	border: 2px solid #000000;
	z-index: 1000;
}

.menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.menu li {
	padding: 10px;
	border-bottom: 2px solid #000000;
	font-family: 'Courier New', monospace;
	text-transform: uppercase;
	font-size: 14px;
	color: black;
}

.menu li:last-child {
	border-bottom: none;
}

.menu li a {
	color: black;
	text-decoration: none;
	display: block;
}

.menu li:hover {
	background-color: #000000;
	color: #ffffff;
	cursor: pointer;
}

.menu li:first-child {
	background-color: #000000;
	color: #ffffff;
	text-transform: none;
	font-size: 12px;
}

.menu li:first-child:hover {
	background-color: #000000;
	cursor: default;
	color: #ffffff;
}

.project-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.add-project-form {
	display: flex;
	width: 50%;
	justify-content: flex-end;
}

.add-project-form input {
	flex-grow: 1;
	padding: 5px;
	font-size: 14px;
	border: 2px solid #000000;
	font-family: 'Courier New', monospace;
}

.add-project-form button {
	padding: 5px 10px;
	font-size: 14px;
	background-color: #000000;
	color: #ffffff;
	border: 2px solid #000000;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
}

.add-project-form button:hover {
	background-color: #ffffff;
	color: #000000;
}

.task-counts {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	color: #ffffff;
}

.task-counts span {
	margin-right: 10px;
	font-weight: bold;
}

.task-counts span::after {
	content: '|';
	margin-left: 10px;
	color: #ffffff;
}

.task-counts span:last-child::after {
	content: '';
}

.task-counts span span {
	margin-left: 5px; /* Decrease space between label and number */
}

/* ... (rest of your existing styles) */

/* Add these styles to your existing App.css file */

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup {
	background-color: white;
	padding: 20px;
	border: 2px solid black;
	max-width: 80%;
	max-height: 80%;
	overflow-y: auto;
}

.popup h2 {
	margin-top: 0;
}

.popup ul {
	list-style-type: none;
	padding: 0;
}

.popup li {
	margin-bottom: 10px;
}

.popup button {
	margin-top: 20px;
}

.auth-container {
	max-width: 300px;
	margin: 0 auto;
	padding: 20px;
	border: 2px solid #000;
}

.auth-container h2 {
	margin-top: 0;
	margin-bottom: 20px;
}

.auth-container input {
	width: 100%;
	margin-bottom: 10px;
}

.auth-container button {
	width: 100%;
	margin-bottom: 10px;
}

.error {
	color: red;
	margin-bottom: 10px;
}

/* Add these styles to your existing App.css file */

.app-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
}

.user-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	max-width: 800px;
	margin-bottom: 20px;
}

.user-info span {
	font-weight: bold;
}

.sign-out-text {
	text-decoration: underline;
	cursor: pointer;
	color: #000000;
}

.sign-out-text:hover {
	opacity: 0.7;
}

/* Update the existing .container style */
.container {
	width: 90%;
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	background-color: #ffffff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* ... (rest of your existing styles) */

/* Existing styles... */

/* You might also want to add styles for the todo list and items */

/* Add these styles to your existing App.css file */

.tabs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 20px;
	width: 100%;
}

.tab {
	flex: 1;
	padding: 10px;
	font-size: 16px;
	background-color: #ffffff;
	color: #000000;
	border: 2px solid #000000;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
	text-align: center;
}

.tab:not(:last-child) {
	border-right: none;
}

.tab.active {
	background-color: #000000;
	color: #ffffff;
}

.tab:hover {
	background-color: #f0f0f0;
	border: 1px solid #000000;
}

.tab.active:hover {
	background-color: #333333;
	border: 1px solid #000000;
}

/* Update the existing styles */

main {
	margin-top: 20px;
	/* Remove the padding */
}

.todo-list {
	list-style-type: none;
	padding: 0;
	/* Ensure there's no padding on the list itself */
}

.todo-item {
	display: flex;
	align-items: center;
	padding: 10px;
	margin-bottom: 10px;
	border: 2px solid #000000;
}

.todo-item input[type='checkbox'] {
	margin-right: 10px;
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border: 2px solid #000000;
	cursor: pointer;
}

.todo-item input[type='checkbox']:checked {
	background-color: #000000;
}

.todo-item span {
	flex-grow: 1;
}

/* ... (rest of your existing styles) */

/* Add these styles to your existing App.css file */

.add-project-form {
	display: flex;
}

.add-project-form input {
	flex-grow: 1;
	padding: 5px;
	font-size: 14px;
	border: 2px solid #000000;
	font-family: 'Courier New', monospace;
}

.add-project-form button {
	padding: 0 10px;
	font-size: 14px;
	background-color: #000000;
	color: #ffffff;
	border: 1px solid #000000;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
	height: 40px;
	box-sizing: border-box;
	transition: all 0.3s ease;
}

.add-project-form button:hover {
	background-color: #333333;
	color: #ffffff;
	border: 1px solid #000000;
}

select {
	padding: 5px;
	font-size: 14px;
	border: 2px solid #000000;
	background-color: #ffffff;
	font-family: 'Courier New', monospace;
	margin-left: 10px;
}

/* Update the existing .user-info style */
.user-info {
	display: flex;
	align-items: center;
	gap: 10px;
}

/* ... (rest of your existing styles) */

/* ... (previous styles remain the same) */

.todo-item.completed {
	text-decoration: line-through;
	opacity: 0.6;
	order: 1;
}

.todo-item {
	display: flex;
	align-items: center;
	padding: 10px;
	margin-bottom: 10px;
	border: 2px solid #000000;
	transition: opacity 0.3s, order 0.3s;
}

.todo-list {
	display: flex;
	flex-direction: column;
}

/* ... (rest of the styles remain the same) */

.tab[data-tab='done'] {
	background-color: #4caf50;
	color: white;
}

.tab[data-tab='done']:hover,
.tab[data-tab='done'].active {
	background-color: #45a049;
}

.todo-item {
	transition: opacity 0.3s, background-color 0.3s;
}

.todo-item.completed {
	text-decoration: line-through;
	opacity: 0.6;
	background-color: #f0f0f0;
}

/* Add these styles to your existing App.css file */

.todo-item input[type='checkbox'].disabled-checkbox {
	background-color: #000000;
	cursor: not-allowed;
}

.todo-item input[type='checkbox'].disabled-checkbox:hover {
	cursor: not-allowed;
}

/* Add these styles to your existing App.css file */

.popup-message {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #000000;
	color: #ffffff;
	padding: 10px 20px;
	border: 2px solid #ffffff;
	font-family: 'Courier New', monospace;
	font-size: 14px;
	z-index: 1000;
	animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
	0%,
	100% {
		opacity: 0;
	}
	10%,
	90% {
		opacity: 1;
	}
}

/* ... (rest of your existing styles) */

/* Add these styles to your existing App.css file */

.task-counts {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.task-counts span {
	margin-right: 10px;
	font-weight: bold;
}

/* ... (rest of your existing styles) */

/* Add these styles to your existing App.css file */

.todo-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.todo-content {
	display: flex;
	align-items: center;
	flex-grow: 1;
}

.delete-btn {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1.2em;
	padding: 0 10px;
	color: #000000;
	transition: color 0.3s ease;
}

.delete-btn:hover {
	color: #ff0000;
}

.delete-btn:focus {
	outline: none;
}

/* ... (rest of your existing styles) */

/* Update these styles in your existing App.css file */

.delete-btn {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
	margin-left: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.delete-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #000000;
	color: #ffffff;
	font-size: 14px;
	line-height: 1;
	transition: background-color 0.3s ease;
}

.delete-btn:hover .delete-icon {
	background-color: #ff0000;
}

.todo-item.completed .delete-icon {
	text-decoration: none;
}

/* ... (rest of your existing styles) */

/* Replace the existing animation styles with these */

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.todo-item {
	transition: opacity 0.5s ease;
}

.todo-item.completed.animating {
	animation: fadeOut 0.5s ease-out forwards;
}

/* ... (rest of the styles remain the same) */

.project-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	gap: 40px;
	height: 40px;
	padding: 0;
}

.project-header h2 {
	margin: 0;
	flex: 1;
	line-height: 40px;
	height: 40px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: min(2em, calc(16px + 2vw));
	min-width: 0; /* This allows text to shrink below flex item's content size */
}

.add-project-form {
	display: flex;
	gap: 10px;
	flex: 2;
	justify-content: flex-start;
	height: 40px;
}

.add-project-form input {
	flex-grow: 1;
	max-width: 300px;
	padding: 0 10px;
	font-size: 14px;
	border: 2px solid #000000;
	font-family: 'Courier New', monospace;
	height: 40px;
	box-sizing: border-box;
}

.add-project-form button {
	padding: 0 10px;
	font-size: 14px;
	background-color: #000000;
	color: #ffffff;
	border: 1px solid #000000;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
	height: 40px;
	box-sizing: border-box;
	transition: all 0.3s ease;
}

.add-project-form button:hover {
	background-color: #333333;
	color: #ffffff;
	border: 1px solid #000000;
}

.project-select {
	flex: 1;
	padding: 0 10px;
	font-size: 14px;
	border: 2px solid #000000;
	background-color: #ffffff;
	font-family: 'Courier New', monospace;
	height: 40px;
	box-sizing: border-box;
}

@media (max-width: 768px) {
	.App {
		padding: 10px 5%;
	}

	.app-header,
	.header-content,
	.header-left,
	.header-right,
	.user-info,
	.task-counts,
	.project-controls,
	.add-project-form,
	.project-select,
	.tabs,
	.todo-form,
	.todo-item,
	.todo-content,
	.project-header {
		width: 100%;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	/* .header-left h1 {
		font-size: 2.5em;
		margin-bottom: 10px;
	} */

	.header-left h1 > a {
		font-size: 12px;
		color: #ffffff;
	}

	.header-left {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		width: 100%;
	}

	.user-info,
	.task-counts {
		margin-bottom: 10px;
	}

	.user-info span,
	.task-counts span,
	.add-project-form input,
	.add-project-form button,
	.project-select,
	.todo-form input,
	.todo-form button {
		width: 100%;
		margin-bottom: 10px;
		box-sizing: border-box;
	}

	.tabs {
		flex-wrap: nowrap;
		overflow-x: auto;
		flex-direction: row;
	}

	.tab {
		flex: 1 0 auto;
		padding: 10px 5px;
		font-size: 14px;
	}

	.todo-item {
		flex-direction: column;
		align-items: center;
	}

	.todo-content {
		flex-direction: column;
		align-items: center;
		margin-bottom: 10px;
	}

	.todo-content input[type='checkbox'] {
		margin-right: 0;
		margin-bottom: 5px;
	}

	.delete-btn {
		align-self: center;
	}

	.project-header {
		flex-direction: column;
		align-items: center;
	}

	.project-header h2 {
		margin-bottom: 10px;
	}

	.project-select {
		width: 100%;
	}

	.popup-message {
		text-align: center;
	}

	.todo-item,
	.todo-content {
		flex-direction: row;
		align-items: center;
	}

	.todo-list,
	.todo-item {
		width: 100%;
	}
}

/* Add these styles to your existing App.css file */

.App.brutus-lite {
	background-color: #f9f9f9;
	color: #333333;
}

.App.brutus-lite .app-header,
.App.brutus-lite main,
.App.brutus-lite .project-header,
.App.brutus-lite .tabs,
.App.brutus-lite .todo-form,
.App.brutus-lite .todo-list {
	background-color: #ffffff;
	border-color: #cccccc;
}

.App.brutus-lite .tab {
	background-color: #ffffff;
	color: #333333;
	border-color: #cccccc;
}

.App.brutus-lite .tab.active {
	background-color: #cccccc;
	color: #ffffff;
}

.App.brutus-lite .todo-item {
	background-color: #ffffff;
	border-color: #cccccc;
}

.App.brutus-lite .todo-item.completed {
	background-color: #eeeeee;
	color: #999999;
}

.App.brutus-lite .delete-btn .delete-icon {
	background-color: #cccccc;
	color: #ffffff;
}

.App.brutus-lite .delete-btn:hover .delete-icon {
	background-color: #999999;
}

.App.brutus-lite .sign-out-btn,
.App.brutus-lite .add-project-form button {
	background-color: #333333;
	color: #ffffff;
	border-color: #333333;
}

.App.brutus-lite .sign-out-btn:hover,
.App.brutus-lite .add-project-form button:hover {
	background-color: #ffffff;
	color: #333333;
	border-color: #333333;
}

.App.brutus-lite .popup-message {
	background-color: #333333;
	color: #ffffff;
	border-color: #ffffff;
}

/* Add these styles to your existing App.css file */

.login-btn {
	background-color: #ffffff;
	color: #000000;
	border: 2px solid #000000;
	padding: 8px 16px;
	font-family: 'Courier New', monospace;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.3s ease;
}

.login-btn:hover {
	background-color: #f0f0f0;
}

/* Add these styles to your existing App.css file */

.login-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.login-form {
	background-color: white;
	padding: 40px;
	border: 2px solid black;
	width: 90%;
	max-width: 320px;
	position: relative;
}

.login-form h2 {
	margin: 0 0 30px 0;
	text-align: center;
	font-size: 2em;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-family: 'Darker Grotesque', sans-serif;
	font-weight: 700;
	color: #000000;
}

.login-form input {
	width: 100%;
	padding: 10px;
	margin-bottom: 15px;
	border: 2px solid black;
	font-family: 'Courier New', monospace;
	font-size: 14px;
	box-sizing: border-box;
}

.login-form button {
	width: 100%;
	padding: 12px;
	margin-bottom: 15px;
	background-color: black;
	color: white;
	border: 2px solid black;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
	font-size: 14px;
}

.login-form button:hover {
	background-color: #333;
}

.close-btn {
	position: absolute;
	top: 15px;
	right: 15px;
	background-color: #000000;
	color: #ffffff;
	border: 2px solid #000000;
	font-size: 20px;
	cursor: pointer;
	padding: 0;
	width: 30px !important;
	height: 30px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 !important;
}

.close-btn:hover {
	background-color: #333333;
}

.error-message {
	color: #ff0000;
	margin-bottom: 15px;
	text-align: center;
	font-size: 12px;
	font-family: 'Courier New', monospace;
}

.google-login-btn {
	background-color: #4285f4 !important;
	border-color: #4285f4 !important;
}

.google-login-btn:hover {
	background-color: #357abd !important;
}

.toggle-auth-mode {
	background: none !important;
	border: none !important;
	color: #000000 !important;
	text-transform: none !important;
	text-decoration: underline;
	font-size: 12px !important;
	padding: 0 !important;
}

.toggle-auth-mode:hover {
	color: #333333 !important;
}

.login-form form {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #eaeaea;
}

/* Update project title styles */
.project-title {
	margin-bottom: 20px;
	text-align: center;
}

.project-title h2 {
	margin: 0;
	font-size: 1.5em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Add these styles for the Assign Tasks button */
.assign-tasks-btn {
	width: 100%;
	padding: 12px;
	margin-top: 20px;
	background-color: #000000;
	color: #ffffff;
	border: 2px solid #000000;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
	font-size: 14px;
	transition: all 0.3s ease;
}

.assign-tasks-btn:hover {
	background-color: #333333;
}

/* Add these styles for the help icon and popup */
.help-icon {
	/* position: relative; */
	/* display: flex;
	align-items: center;
	justify-content: center; */
	background-color: #ff0000;
	color: #ffffff;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	cursor: pointer;
	font-weight: bold;
	font-size: 14px;
	border: 2px solid #ff0000;
	margin-left: 10px;
	vertical-align: middle;
}

.help-icon:hover {
	background-color: #cc0000;
}

.help-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #000000;
	color: #ffffff;
	padding: 30px;
	border: 2px solid #ffffff;
	max-width: 600px;
	max-height: 80vh;
	width: 90%;
	font-family: 'Courier New', monospace;
	z-index: 2000;
	overflow-y: auto;
	box-sizing: border-box;
}

.help-popup h2 {
	font-size: 24px;
	margin-bottom: 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.help-popup div {
	margin-right: 10px;
}

.help-popup::-webkit-scrollbar {
	width: 10px;
}

.help-popup::-webkit-scrollbar-track {
	background: #333333;
}

.help-popup::-webkit-scrollbar-thumb {
	background: #ffffff;
	border: 2px solid #000000;
}

.help-popup::-webkit-scrollbar-thumb:hover {
	background: #cccccc;
}

.help-popup p {
	margin-bottom: 15px;
	line-height: 1.6;
}

.help-popup button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: #ffffff;
	color: #000000;
	border: none;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.help-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 1999;
}

/* Add/update these styles */
.help-text {
	font-size: 12px;
	background-color: #ff0000;
	color: #ffffff;
	padding: 2px 6px;
	margin-left: 10px;
	text-decoration: none;
	font-family: 'Courier New', monospace;
	font-weight: normal;
	letter-spacing: 1px;
}

.help-text:hover {
	background-color: #cc0000;
	color: #ffffff;
}

/* Update the header left h1 styles */
.header-left h1 {
	margin: 0;
	font-size: 3em;
	text-transform: uppercase;
	letter-spacing: 6px;
	font-family: 'Darker Grotesque', sans-serif;
	font-weight: 700;
	display: flex;
	align-items: center;
}

/* Add these styles for the payment modal */
.payment-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
}

.payment-modal-content {
	background-color: #000000;
	color: #ffffff;
	padding: 40px;
	border: 2px solid #ffffff;
	max-width: 500px;
	width: 90%;
	position: relative;
	font-family: 'Courier New', monospace;
}

.payment-modal h2 {
	text-align: center;
	font-size: 2em;
	margin-bottom: 30px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.price-box {
	border: 2px solid #ffffff;
	padding: 20px;
	margin-bottom: 30px;
}

.price-box h3 {
	text-align: center;
	font-size: 2.5em;
	margin-bottom: 20px;
}

.price-box ul {
	list-style: none;
	padding: 0;
}

.price-box li {
	padding: 10px 0;
	text-align: center;
	font-size: 1.2em;
}

.subscribe-btn {
	width: 100%;
	padding: 15px;
	background-color: #ffffff;
	color: #000000;
	border: none;
	font-size: 1.2em;
	cursor: pointer;
	text-transform: uppercase;
	font-family: 'Courier New', monospace;
	transition: all 0.3s ease;
}

.subscribe-btn:hover {
	background-color: #f0f0f0;
}

.subscribe-btn:disabled {
	background-color: #666666;
	cursor: not-allowed;
}

/* Landing Page Styles */
.landing-page {
	min-height: 100vh;
	background-color: #000000;
	color: #ffffff;
	padding: 40px 20px;
}

.landing-content {
	max-width: 800px;
	margin: 0 auto;
}

.landing-page h1 {
	font-size: 4em;
	text-align: center;
	margin-bottom: 20px;
	font-family: 'Darker Grotesque', sans-serif;
	letter-spacing: 10px;
}

.landing-page h2 {
	font-size: 1.5em;
	text-align: center;
	margin-bottom: 60px;
	font-family: 'Courier New', monospace;
	border: 2px solid #ffffff;
	padding: 20px;
}

.feature-section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
	margin-bottom: 60px;
}

.feature {
	border: 2px solid #ffffff;
	padding: 20px;
	text-align: center;
}

.feature h3 {
	font-size: 1.2em;
	margin-bottom: 15px;
	font-family: 'Courier New', monospace;
}

.feature p {
	font-size: 1em;
	line-height: 1.4;
}

.how-it-works {
	margin-bottom: 60px;
}

.how-it-works h3 {
	text-align: center;
	font-size: 1.5em;
	margin-bottom: 30px;
	font-family: 'Courier New', monospace;
}

.how-it-works ol {
	list-style: none;
	padding: 0;
}

.how-it-works li {
	margin-bottom: 30px;
	border: 2px solid #ffffff;
	padding: 20px;
}

.step {
	font-size: 1.2em;
	font-weight: bold;
	display: block;
	margin-bottom: 10px;
	font-family: 'Courier New', monospace;
}

.cta-section {
	text-align: center;
	border: 2px solid #ffffff;
	padding: 40px;
}

.cta-section h3 {
	font-size: 2em;
	margin-bottom: 20px;
	font-family: 'Courier New', monospace;
}

.cta-section p {
	font-size: 1.2em;
	margin-bottom: 30px;
}

.cta-button {
	background-color: #ffffff;
	color: #000000;
	border: none;
	padding: 20px 40px;
	font-size: 1.2em;
	cursor: pointer;
	font-family: 'Courier New', monospace;
	text-transform: uppercase;
	transition: all 0.3s ease;
}

.cta-button:hover {
	background-color: #ff0000;
	color: #ffffff;
}

@media (max-width: 768px) {
	.feature-section {
		grid-template-columns: 1fr;
	}

	.landing-page h1 {
		font-size: 3em;
	}

	.landing-page h2 {
		font-size: 1.2em;
	}

	.cta-button {
		width: 100%;
	}
}

/* Update border widths from 2px to 1px */

.landing-page h2 {
	border: 1px solid #ffffff;
}

.feature {
	border: 1px solid #ffffff;
}

.how-it-works li {
	border: 1px solid #ffffff;
}

.cta-section {
	border: 1px solid #ffffff;
}

/* Login popup styles */
.login-form {
	border: 1px solid #000000;
}

.login-form input {
	border: 1px solid #000000;
}

.login-form button {
	border: 1px solid #000000;
}

/* For the main app */
.todo-item {
	border: 1px solid #000000;
}

.tab {
	border: 1px solid #000000;
}

.project-select {
	border: 1px solid #000000;
}

.add-project-form input,
.add-project-form button {
	border: 1px solid #000000;
}

/* Menu styles */
.menu {
	border: 1px solid #000000;
}

.menu li {
	border-bottom: 1px solid #000000;
}
